import { Component } from "react";
import ReactMarkdown from "react-markdown";
import { Collapse, Button, Row, Col, Modal, Tabs, Input, Spin, Typography, Divider, Card } from "antd";
import { MinusOutlined, DownOutlined, PrinterFilled } from "@ant-design/icons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import * as SyntaxThemes from "react-syntax-highlighter/dist/esm/styles/prism";
import _ from "lodash";

import "./style.css";

export default class RestDocJS extends Component {
	static defaultOptions = {
		theme: "gigadat", // ["swagger", "gigadat"]
		defaultCodeExample: "javascript"
	};

	state = { 
		values: {
			loading: false,
			response: null
		} 
	};

	render() {
		const { values } = this.state;
		const { modalVisible, setModalVisible, openKeys, content: schema } = this.props;
		const config = _.merge(RestDocJS.defaultOptions, this.props.options);

		// Categorize by tag
		//const { tags } = schema;
		//const taggedPaths = _.map(tags, tag => {
		//	tag.paths = _.map(schema.paths, (path, key) => {
		//		const toReturn = _.find(path, (path, key) => _.indexOf(path.tags, tag.name) > -1);
		//		if(!_.isEmpty(toReturn)) return key;
		//	}, {});

		//	tag.paths = _.compact(tag.paths);
		//	return tag;
		//});

		if(_.isEmpty(schema)) return null;

		return (
			<div id="rest-doc">
				
				<Row align="middle" justify="space-between">
					<Col span={20}>
						<Typography.Title style={{ margin: 0 }}>{ schema.info?.title }<small style={{ color: "var(--ant-primary-color)"}}> { schema.info?.version }</small></Typography.Title>
					</Col>
					<Col span={4} style={{ textAlign: "right" }}>
						{ _.isFunction(this.props.onPrint) && <Button type="primary" icon={<PrinterFilled />} className="no-print" style={{ backgroundColor: "var(--ant-info-color)" }} onClick={() => this.props.onPrint()}>Print</Button> }
					</Col>
				</Row>
				
				<div style={{ marginBottom: "20px" }}>
					<Typography.Paragraph>{ schema.info?.description }</Typography.Paragraph>
							
					<div className="button-group no-print">
					{
						_.map(schema.servers, server => (
							<Button
								style={{ backgroundColor: server.type === "Postman" && "#f15a24", color: "white" }} 
								icon={<img style={{ height: "100%", marginRight: "10px" }} alt={server.type} src={`${server.type === "Postman" && "/postman.png"}`} />} 
								onClick={() => window.open(server.url, "_blank").focus()}
							>
								{ server.description }
							</Button>
						))
					}
					</div>
				</div>

				{
					_.map(schema.tags, tag => (
						<>
							{/*<div style={{ margin: "20px" }} id={_.kebabCase(_.toLower(tag.name))}>
								<Typography.Title level={4}>{ tag.name }</Typography.Title>
							</div>*/}

							<Collapse
								expandIcon={({isActive}) => isActive ? <DownOutlined /> : <MinusOutlined />}
								expandIconPosition="right"
								defaultActiveKey={[`${decodeURI(this.props.history.location.hash)}`.substring(1), openKeys]} 
								style={{ overflow: "hidden", marginBottom: "20px" }}
								className="api-container"
							>
							<div style={{ margin: "20px" }} id={_.kebabCase(_.toLower(tag.name))}>
								<Typography.Title level={4}>{ tag.name }</Typography.Title>
							</div>
							{
								_.map(_.filter(schema.paths, path => _.indexOf(path.tags, tag.name) > -1), path => (
									<Collapse.Panel id={`${tag.name}${path.name}${path.method}`} key={`${tag.name}${path.name}${path.method}`} header={(
										<Row align="middle" gutter={20}>
											<Col>
												{
													path.method === "post" ? (
														<Button style={{ minWidth: "65px" }} type="primary">{ _.toUpper(path.method) }</Button>
													) : (
														<Button style={{ minWidth: "65px", backgroundColor: "#7aea6a", color: "white" }}>{ _.toUpper(path.method) }</Button>
													)
												}
											</Col>
											<Col><code>{`{gigadatUrl}`}{ path.name }</code></Col>
											<Col span={{ xs: 24 }}><span>{ path.summary }</span></Col>
										</Row>
									)}>
										<Row justify="space-between" align="top">
											<Col span={16}>
												<p>{ this.renderMarkdown(path.description) }</p>
											</Col>

											<Col span={8}>
												{/* Security: Try It Outs  */}
												{ !_.isEmpty(path.security) && (
													<Row align="end">
														<Col className="button-group">
														{
															_.map(path.security, security => {
																// Swagger YAML uses this data structure, not me
																const securityKey = _.first(_.keys(security));
																switch(securityKey) {
																	case "Javascript": 
																		return <Button className="no-print" type="primary" onClick={() => setModalVisible(security)}>Try It Out</Button>

																	case "Postman": 
																		return <Button className="no-print" style={{ backgroundColor: "#f15a24", color: "white" }} icon={<img style={{ height: "100%", marginRight: "10px" }} alt="postman" src="/postman.png" />} onClick={() => window.open(security.Postman.url, "_blank").focus()}>Try on Postman</Button>;

																	default: 
																		return null;
																}
															})
														}
														</Col>
													</Row>
												)}
											</Col>
										</Row>
										
										{/* Parameters */}
										{/*{ !_.isEmpty(api.parameters) && <Divider orientation="left">Parameters</Divider> }*/}
										{
											_.map(path.parameters, parameter => (
												<>
													<Row align="top" style={{ margin: "20px 0" }}>
														<Col span={6}>
															<code style={{ marginRight: "20px" }}>{ parameter.name }{ parameter.required && <small style={{ color: "red" }}> * required</small> }</code>
															<br/><small>{ !_.isEmpty(parameter.schema?.type) ? parameter.schema?.type : parameter.schema?.$ref ? "object" : null }</small>
															<br/><i>{ parameter.in }</i>
														</Col>
														<Col span={18}>{ this.renderMarkdown(parameter.description) }</Col>
													</Row>

													<Row>
														{/* Code Examples */}
														{
															!_.isEmpty(parameter.examples) && (
																<Col span={24} style={{ marginTop: "20px" }}>
																	<Tabs defaultActiveKey={[config.defaultCodeExample]}>
																	{
																		_.map(parameter.examples, (example, key) => (
																			<Tabs.TabPane tab={key} key={key}>
																				{ this.renderMarkdown(`\`\`\`${_.toLower(key)}\n${_.isObject(example.value) ? JSON.stringify(example.value, null, "\t") : example.value} \n\`\`\``) }
																			</Tabs.TabPane>
																			))
																		}
																	</Tabs>
																</Col>
															)
														}
													</Row>
												</>
											))
										}

										{ !_.isEmpty(path.responses) && <Divider orientation="left">Responses</Divider> }
										{
											_.map(path.responses, (response, key) => (
												<Row align="top" style={{ margin: "20px 0" }}>
													<Col span={6} style={{ textAlign: "right", paddingRight: "20px"  }}>
														<code>{ key }</code>
														{ _.map(response.content, (value, key) => <><br /><code>{ key }</code></>) }
													</Col>
													<Col span={18}>
														{ !_.isEmpty(response.description) && <Col span={24} style={{ marginBottom: "20px" }}><Typography.Paragraph style={{ whiteSpace: "pre-wrap" }}>{ response.description }</Typography.Paragraph></Col> }
														{
															_.map(response.content, (value, key) => {
																switch(key) {
																	case "application/json": 
																		return <Col>{ this.renderMarkdown(`\`\`\`javascript\n${JSON.stringify(value.example, null, "\t")}\n\`\`\``) }</Col>
																	
																	case "text/plain":
																		return <Col>{ this.renderMarkdown(`\`\`\`none\n${value.example}\n\`\`\``) }</Col>
																		
																	default:
																		return null;
																}
															})
														}
													</Col>
												</Row>
											))
										}
									</Collapse.Panel>
								))
							}
							</Collapse>
						</>
					))
				}

				{/* Try It Out */}
				<Modal title="Try It Out" visible={!_.isEmpty(modalVisible)} footer={null} destroyOnClose onCancel={() => setModalVisible(false)}>
					<Spin spinning={!!values.loading}>
						{
							_.map(modalVisible, (value, key) => (
								<Row align="middle">
									<Col span={24}>
										<b>API Endpoint</b>
										<Input 
											style={{ marginBottom: "10px" }} 
											value={value.server}
										/>
										
										{
											_.map(value.parameters, (parameter, key) => key !== "body" &&  (
												<>
													<b>{key}</b>
													<Input 
														style={{ marginBottom: "10px" }} 
														value={!_.isUndefined(values[key]) ? values[key] : parameter}
														onChange={({ target: { value }}) => this.setState({ values: { ...values, [key]: value }})}
													/>
												</>
											))
										}
									</Col>

									<Col span={24}>
										<b>Body</b>
										<Input.TextArea 
											rows={5}
											value={this.state.values.body || JSON.stringify(value.parameters?.body, null, "\t")}
											onChange={({ target: { value } }) => this.setState({ values: { body: value }}) }
										/>
									</Col>

									<Col style={{ marginTop: "20px" }}>
										<Button
											type="primary"
											loading={values.loading}
											onClick={async () => {
												// Get all the parameters that are not in the body
												const fetchParams = _.merge({}, modalVisible[key].parameters, this.state.values);

												// Parse the server URL to replace `:parameter` tokens to their appropriate value
												const fetchUrl = _.reduce(fetchParams, (acc, param, key) => acc = _.replace(acc, `:${key}`, param), value.server);
												
												this.setState({ values: { ...values, loading: true }});
												try {
													const response = await fetch(`http://127.0.0.1:8080${fetchUrl}`, {
														method: value.method,
														body: _.isString(fetchParams.body) ? JSON.stringify(JSON.parse(fetchParams.body)) : JSON.stringify(fetchParams.body),
														headers: {
															authorization: await localStorage.getItem("token"),
															"Content-Type": "application/json"
														}
													}).catch(error => this.setState({ values: { ...values, loading: false, response: error.message }}));
	
													if(response?.status === 200) {
														return this.setState({ values: { ...values, loading: false, response: JSON.stringify(await response.json(), null, "\t") }})
													}
													if(response?.status !== 200) {
														return this.setState({ values: { ...values, loading: false, response: await response.text() }})
													}

												} catch(error) {
													console.error(error);
													this.setState({ values: { ...values, loading: false, response: error.message }});
												}
											}}
										>
											Run
										</Button>
									</Col>
									
									{
										!_.isEmpty(values.response) && (
											<Col span={24} style={{ marginTop: "20px" }}>
												<b>Response</b>
												<div style={{ maxHeight: "576px", overflow: "auto" }}>
													{ this.renderMarkdown(`\`\`\`javascript\n${values.response}\n\`\`\``) }
												</div>
											</Col>
										)
									}
								</Row>
							))
						}
					</Spin>
				</Modal>
			</div>
		);
	}

	renderMarkdown(toRender) {
		return (
			<ReactMarkdown 
					children={toRender}
					components={{
						code({node, inline, className, children, ...props}) {
							const match = /language-(\w+)/.exec(className || "")
							return !inline && match ? (
								<div className="code-container">
									<SyntaxHighlighter
										children={String(children).replace(/\n$/, "")}
										style={_.merge(SyntaxThemes.tomorrow, {
											"code[class*=\"language-\"]": {
												"color": "#00000080",
												"background": "none"
											},
											"pre[class*=\"language-\"]": {
												"background": "none",
												"padding": 0,
												"overflow": "none"
											},
											":not(pre) > code[class*=\"language-\"]": {
												"background": "none",
												"padding": 0
											}
										})}
										language={match[1]}
										PreTag="div"
										{...props}
									/>
								</div>
							) : (
								<code className={className} {...props}>
									{children}
								</code>
							)
						}
					}}
				/>
		);
	}
}