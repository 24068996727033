import { useContext, useEffect } from "react";
import { Redirect, useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import _ from "lodash";

import { Server } from "src/services";
import { ContextUser, ContextSettings, ContextTechDoc } from "src/components";
import Component from "./component";


export default function TechDoc(props) {
	const [user, setUser] = useContext(ContextUser);
	const [settings, setSettings] = useContext(ContextSettings);
	const [content, setContent] = useContext(ContextTechDoc);
	const history = useHistory(); 
	const { hash } = useLocation();
	
	useEffect(() => {
		async function fetchData() {
			if(_.isEmpty(user?.token)) return;
			// Get the documentation content according the the user token
			const techDocResponse = await fetch(`${Server.URL}/docs/tech`, { headers: { "authorization": user?.token }});
			if(!techDocResponse.ok) return setContent() || setUser();
			const techDoc = await techDocResponse.text();
			setContent(techDoc);
		};
		_.isEmpty(content) && fetchData();
	}, [user])

	const printDoc = useReactToPrint({ content: () => document.getElementById("tech-doc") });
	
	// Redirect if the user is already logged in
	if(_.isEmpty(user)) return <Redirect to="/login" />;
	return (
		<Component
			{...props}
			history={history}
			settings={settings} setSettings={setSettings}
			content={content} setContent={setContent}
			onPrint={printDoc}
			hash={hash}
		>
			{ content }
		</Component>
	);
}