import { Layout } from "antd";

export default function Sider(props) {
	const { children } = props;
	return (
		<Layout.Sider
			theme="light"
			width={props.width}
			style={{
				overflow: "hidden auto",
				height: "100vh",
				position: "fixed",
				left: 0
			}}
		>
			<div style={{ width: "80%", margin: "20px auto" }}>
				<img
					alt="banner"
					src="/banner.png" 
					style={{
						width: "100%",
						objectFit: "cover"
					}}
				/>
			</div>
			{ props.children }
			
			<div style={{ height: "min(300px, 50vh)" /* Bottom padding for UX when clicking REST API menu item */ }} /> 
		</Layout.Sider>
	);
}