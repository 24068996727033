import { Component } from "react";
import { Typography, Input, Button, Row, Col, Alert } from "antd";
import _ from "lodash";
import "./style.css";


export default class Root extends Component {
	state = {
		login: ""
	};

	render() {
		const { error, setError } = this.props;

		return (
			<Row justify="space-around" style={{ height: "80vh" }}>
				<Col xs={20} sm={12} style={{ margin: "auto" }}>
					<img
						alt="banner"
						src="/banner.png" 
						style={{
							width: "100%",
							objectFit: "cover"
						}}
					/>

					<Typography.Title level={1} style={{ textAlign: "center", margin: "40px 0" }}>Documentation Portal</Typography.Title>

					<Input.Search
						placeholder="Insert token provided by Gigadat"
						enterButton="Login"
						value={this.state.login} 
						onChange={event => this.setState({ login: event.target.value })}
						onSearch={() => this.onLogin()}
					/>

					{
						_.isError(error) && (
							<Alert
								banner
								showIcon
								closable
								type="error"
								onClose={() => setError()}
								message={error.message}
								style={{ margin: "20px 0", borderRadius: "5px" }}
							/>
						)
					}

					{/*<Row justify="space-around" className="button-group" style={{ marginTop: "20px" }}>
						<Col>
							<Button onClick={() => this.setState({ login: "e6d5f24ace1184308f15a316ebd51b33" })}>Interac Express</Button>
							<Button onClick={() => this.setState({ login: "00049b373ef77a82d0ecf9350fc414c7" })}>ReelVegas</Button>
						</Col>
					</Row>*/}
				</Col>
			</Row>
		);
	}

	async onLogin() {
		const { login } = this.state;
		this.props.onLogin(_.trim(login));
	}
}