import { useState, useContext, useEffect } from "react";
import { Redirect, useHistory } from "react-router";
import { useReactToPrint } from "react-to-print";
import _ from "lodash";

import { Server } from "src/services";
import { ContextUser, ContextRestDoc } from "src/components";
import Component from "./component";


export default function RestDocJS(props) {
	const [user, setUser] = useContext(ContextUser);
	const [modalVisible, setModalVisible] = useState(false);
	const [layout, setLayout] = useState(0);
	const history = useHistory(); 
	const [content, setContent] = useContext(ContextRestDoc);
	const printDoc = useReactToPrint({ content: () => document.getElementById("rest-doc") });
	
	useEffect(() => {
		async function fetchData() {
			if(_.isEmpty(user?.token)) return;
			const schemaResponse = await fetch(`${Server.URL}/docs/api`, { headers: { "authorization": user?.token }});
			if(!schemaResponse.ok) return setContent() || setUser();
			const schema = await schemaResponse.json();
			setContent(schema);
		};
		_.isEmpty(content) && fetchData();
		console.log({ RestContent: content })
	}, [user])

	
	// Redirect if the user is already logged in
	if(_.isEmpty(user)) return <Redirect to="/login" />;


	return (
		<Component
			{...props}
			user={user}
			history={history}
			modalVisible={modalVisible} setModalVisible={setModalVisible}
			layout={layout} setLayout={setLayout}
			content={content} setContent={setContent}

			onPrint={printDoc}
		/>
	);
}