import { Component } from "react";
import { Typography, Row, Col } from "antd";
import _ from "lodash";

export default class Disabled extends Component {
	render() {
		return (
			<Row justify="space-around" style={{ height: "80vh" }}>
				<Col xs={20} sm={12} style={{ margin: "auto" }}>
					<img
						alt="banner"
						src="/banner.png" 
						style={{
							width: "100%",
							objectFit: "cover"
						}}
					/>

					<Typography.Title level={1} style={{ textAlign: "center", margin: "40px 0" }}>Documentation Portal</Typography.Title>
					
					<div style={{
						borderRadius: "10px",
						padding: "20px",
						width: "100%",
						background: `repeating-linear-gradient(
							-45deg,
							var(--ant-primary-color),
							var(--ant-primary-color) 10px,
							transparent 10px,
							transparent 20px
						)`
					}}>
						<div style={{ padding: "20px", backgroundColor: "white", borderRadius: "10px" }}>
							This site is under construction. Please check back later.<br />
							For support, contact <a href="mailto:onboarding@gigadatsolutions.com">onboarding@gigadatsolutions.com</a>
						</div>
					</div>
				</Col>
			</Row>
		);
	}
}