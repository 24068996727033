import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import * as SyntaxThemes from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import { Button, Row, Col, Typography } from "antd";
import { PrinterFilled } from "@ant-design/icons";
import _ from "lodash";

import "./style.css";

export default class TechDoc extends Component {
	componentDidMount() {
		setTimeout(() => {
			const { hash } = this.props;
			if(hash) document.getElementById(`${hash}`.substring(1))?.scrollIntoView({ behavior: "smooth" });
		}, 300);
	}

	state = {};
	render() {
		const { settings, children } = this.props;

		return (
			<div id="tech-doc" style={{ marginBottom: "20vh" }}>
				<Row align="middle" justify="space-between">
					<Col span={20}>
						<Typography.Title id="gigadat-payment-integration" style={{ margin: 0 }}>Gigadat Payment Integration</Typography.Title>
					</Col>
					<Col span={4} style={{ textAlign: "right" }}>
						{ _.isFunction(this.props.onPrint) && <Button type="primary" icon={<PrinterFilled />} className="no-print" style={{ backgroundColor: "var(--ant-info-color)" }} onClick={() => this.props.onPrint()}>Print</Button> }
					</Col>
				</Row>
				
				<ReactMarkdown 
					children={children}
					renderers={{ 
						heading: HeadingRenderer
					}} 
					remarkPlugins={[remarkGfm]}
					components={{
						a: ({ node, inline, className, children, ...props }) => <HashLink scroll={el => el.scrollIntoView({ behavior: "smooth" })} to={decodeURI(node.properties.href)} {...props}><Button type="primary" size="small">{ children }</Button></HashLink>,
						h1: ({ node, inline, className, children, ...props }) => <HeadingRenderer {...props}>{ children }</HeadingRenderer>,
						h2: ({ node, inline, className, children, ...props }) => <HeadingRenderer {...props}>{ children }</HeadingRenderer>,
						h3: ({ node, inline, className, children, ...props }) => <HeadingRenderer {...props}>{ children }</HeadingRenderer>,
						code({node, inline, className, children, ...props}) {
							const match = /language-(\w+)/.exec(className || "")
							return !inline && match ? (
								<div className="code-container">
									<SyntaxHighlighter
										children={String(children).replace(/\n$/, "")}
										style={_.merge(SyntaxThemes.tomorrow, {
											"code[class*=\"language-\"]": {
												"color": "#00000080",
												"background": "none"
											},
											"pre[class*=\"language-\"]": {
												"background": "none",
												"padding": 0,
												"overflow": "none"
											},
											":not(pre) > code[class*=\"language-\"]": {
												"background": "none",
												"padding": 0
											}
										})}
										language={match[1]}
										PreTag="div"
										{...props}
									/>
								</div>
							) : (
								<code className={className} {...props}>
									{children}
								</code>
							)
						}
					}}
				/>
			</div>
		);
	}
}

function flatten(text, child) {
	return typeof child === "string"
		? text + child
		: React.Children.toArray(child.props.children).reduce(flatten, text)
}

function HeadingRenderer(props) {
  const children = React.Children.toArray(props.children)
  const text = children.reduce(flatten, '')
  const slug = text.toLowerCase().replace(/\W/g, '-')
  return React.createElement("h" + props.level, { id: slug }, props.children);
}