import { Component } from "react";
import Iframe from "react-iframe";

import { Server } from "src/services";
import "./style.css";

export default class RestDoc extends Component {
	render() {
		const { user } = this.props;
		return (
			<Iframe 
				id="doc-iframe"
				url={`${Server.URL}/docs/api/${user.token}?swagger=true`}
			/>
		);
	}
}