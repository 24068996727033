import { useState, useContext } from "react";
import { Redirect } from "react-router";
import _ from "lodash";

import { Server } from "src/services";
import { ContextUser } from "src/components";
import Component from "./component";

export default function Root(props) {
	// Redirect if the user is already logged in
	const [user, setUser] = useContext(ContextUser);
	const { error, setError } = props;

	if(!_.isEmpty(user)) return <Redirect to="/doc" />;

	return (
		<Component
			error={error} setError={setError}
			onLogin={async token => {
				setError();
				if(_.isEmpty(token)) return;
				
				await localStorage.setItem("token", token);
				return window.location.href = "/";
				
				const authRequest = await fetch(`${Server.URL}/auth`, { headers: { "authorization": token }});
				if(authRequest.status !== 200) {
					setError(new Error("Invalid token provided"));
					return await localStorage.removeItem("token"); 
				}
				//const user = await authRequest.json();
				
				setUser({ token });
			}}
		/>
	);
}