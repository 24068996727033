class Server {
	URL = "";

	constructor() {
		switch(process.env.NODE_ENV) {
			case "production": {
				this.URL = ""; // Relative Path
				break;
			}
			case "development":
			default: {
				this.URL = `http://127.0.0.1:8080`;
			}
		}
	}
}

export default new Server();