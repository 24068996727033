import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

// Libraries
import jwt from "jwt-decode";
import { HashRouter, Route, Switch } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { ConfigProvider } from "antd";
import _ from "lodash";

// Styles
import { Layout } from "antd";
import "antd/dist/antd.variable.min.css";
import "./style.css";

// Pages
import { Root, RestDocJS, TechDoc } from "src/views";

// Components
import { ContextUser, ContextSettings, ContextRestDoc, ContextTechDoc, Sider, Footer, Menu, Disabled } from "src/components";

// Services
import { Server } from "src/services";

// This function lets us use async/await syntax before rendering React components
async function start() {
	ConfigProvider.config({
		theme: {
			primaryColor: "#eea906",

			/**
			 * @primary-color: #1890ff; // primary color for all components
			 * @link-color: #1890ff; // link color
			 * @success-color: #52c41a; // success state color
			 * @warning-color: #faad14; // warning state color
			 * @error-color: #f5222d; // error state color
			 * @font-size-base: 14px; // major text font size
			 * @heading-color: rgba(0, 0, 0, 0.85); // heading text color
			 * @text-color: rgba(0, 0, 0, 0.65); // major text color
			 * @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
			 * @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
			 * @border-radius-base: 2px; // major border radius
			 * @border-color-base: #d9d9d9; // major border color
			 * @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
					0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
			 */
		}
	});

	// Login through URL
	const urlParams = new URLSearchParams(window.location.search);
	let token = urlParams.get("id");
	if(!_.isEmpty(token)) {
		await localStorage.setItem("token", token);

		// Temporary Disable
		//await localStorage.removeItem("token");
		//return ReactDOM.render(
		//	<Disabled />,
		//	document.getElementById("root")
		//);
	} else {
		token = await localStorage.getItem("token");
	} 

	let activeUser;
	try {
		const authRequest = await fetch(`${Server.URL}/auth`, { headers: { "authorization": token }});
		if(authRequest.status === 200) activeUser = { token };
		else await localStorage.removeItem("token");
	} catch(error) {
		console.error(error);
	}
	
	function Routes(props) {
		const [settings, setSettings] = useState();
		const [techContent, setTechContent] = useState();
		const [restContent, setRestContent] = useState();
		const [error, setError] = useState();
		const [menu, setMenu] = useState();
		
		// Set the User Context from the JWT token in storage
		const [user, setUser] = useState(activeUser);
		
		useEffect(() => {
			async function fetchData() {
				// Get the documentation content according the the user token
				//const techDoc = await (await fetch(`${Server.URL}/docs/tech`, { headers: { "authorization": user?.token }})).text();
				//setContent(techDoc);

				// Set the menu according the the user token
				const menuResponse = await fetch(`${Server.URL}/docs/menu`, { headers: { "authorization": user?.token }});
				if(!menuResponse.ok) return setUser() || setMenu() || setError(new Error("Invalid token or it has expired"));
				const menu = await menuResponse.json();
				setMenu(menu);
			};
			fetchData();
		}, [user]);

		const siderWidth = 250;
		
		return (
			<HashRouter>
				<ContextSettings.Provider value={[settings, setSettings]}>
					<Layout>
						{
							!_.isEmpty(user) && (
								<Sider width={siderWidth}>
									<Menu 
										activeKey={`${props.location?.hash}`.substring(1, `${props.location?.hash}`.length)} 
										menus={menu}
										renderItem={menu => <HashLink scroll={el => el.scrollIntoView({ behavior: "smooth" })} to={`/doc#${menu.key}`}>{ menu.title }</HashLink>}
										onLogout={async () => {
											await localStorage.removeItem("token");
											setUser();
										}}
									/>
								</Sider>
							)
						}

						<Layout.Content style={{ marginLeft: user && siderWidth }}>
							<ContextUser.Provider value={[user, setUser]}>
								<ContextRestDoc.Provider value={[techContent, setTechContent]}>
									<ContextTechDoc.Provider value={[restContent, setRestContent]}>
										<Switch>
											<Route exact path="/doc/api" component={RestDocJS} />
										
											<Route exact path="/doc?campaign=:campaign" component={TechDoc} />
											<Route exact path="/doc" component={TechDoc} />
											
											<Route exact path="/login" render={props => <Root error={error} setError={setError} {...props} />} />
											<Route path="/" render={props => <Root error={error} setError={setError} {...props} />} />
										</Switch>
									</ContextTechDoc.Provider>
								</ContextRestDoc.Provider>
							</ContextUser.Provider>
							
							<Footer />
						</Layout.Content>
						
					</Layout>
				</ContextSettings.Provider>
			</HashRouter>
		);
	}

	ReactDOM.render(
		<Routes />,
		document.getElementById("root")
	);
}

start();
