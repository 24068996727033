import { HashLink } from "react-router-hash-link";
import { Menu } from "antd";
import { StopOutlined, BulbFilled, DatabaseFilled } from "@ant-design/icons";
import _ from "lodash";
import "./style.css";

export default function SidebarMenu(props) {
	const { activeKey, menus } = props;

	return (
		<Menu 
			mode="inline" 
			defaultSelectedKeys={[activeKey]}
			onClick={({ key }) => props.onClick && props.onClick(key)}
		>
			{
				_.map(menus, menu => !_.isEmpty(menu.menu) ? (
					<Menu.SubMenu key={menu.key} icon={<BulbFilled />} title={menu.title}>
						{
							_.map(menu.menu, menu => (
								<Menu.Item key={menu.key}>
									{ props.renderItem(menu) }
								</Menu.Item>
							))
						}
					</Menu.SubMenu>
				) : (
					<Menu.Item key={menu.key} icon={<BulbFilled />}>
						{ props.renderItem(menu) }
					</Menu.Item>
				))
			}
			
			<Menu.ItemGroup key="api" title="API">
				<Menu.Item key="/doc/api" icon={<DatabaseFilled />}><HashLink to="/doc/api">REST API</HashLink></Menu.Item>
			</Menu.ItemGroup>

			{/*<Menu.ItemGroup key="debug" title="Debug">
				<Menu.Item key="logout" icon={<SettingFilled />} onClick={() => props.onLogout && props.onLogout()}>Logout</Menu.Item>
			</Menu.ItemGroup>*/}
		</Menu>
	);
}